<template>
  <div class="schedul-detail module-main-body">
    <div class="btn-container module-content-top-bar">
      <!--取消订单-->
      <el-button
        v-if="
          ![4, 5].includes(jobData.status) &&
          jobData.status &&
          !isCanceled &&
          hasPermission($permissionFunctionCode.EDIT)
        "
        style="min-width: 100px"
        type="danger"
        size="small"
        round
        :loading="cancelLoading"
        @click="cancelJop"
      >
        Cancel Job
      </el-button>
      <!--提交-->
      <el-button
        v-if="![3, 4, 5].includes(jobData.status)"
        style="min-width: 100px"
        type="primary"
        size="small"
        round
        :loading="submitLoading"
        @click="submitForm"
      >
        Submit
      </el-button>
      <!--关闭当前页-->
      <el-button
        style="min-width: 100px"
        outline
        size="small"
        round
        @click="$emit('close', (noAction = true))"
      >
        Close
      </el-button>
    </div>
    <div style="overflow-y: auto; height: calc(100% - 42px)">
      <base-detail-card
        title="Files"
        v-if="jobData.status && [3, 4].includes(jobData.status)"
      >
        <job-files :jobFiles.sync="jobFiles" />
      </base-detail-card>
      <base-detail-card title="Loading Information"  v-if="isEdit" v-loading="!jobDataInner">
        <!--    Ex-Wharf Information-->
        <el-form
          :disabled="!isJobCreatorOrSellerEditView"
          ref="deliveredScheduleForm"
          :model="jobDataInner"
          label-position="top"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Product Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner || {}).productName"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Barge Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner || {}).bargeName"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="eBDN Date" style="width: 100%">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner || {}).docDate"
                  type="date"
                  readonly
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="Delivered Quantity">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner || {}).docQty"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item label="ETC">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner || {}).etc"
                  type="date"
                  readonly
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item v-if="(jobDataInner || {}).cqPdfId" label="Documents">
                <el-button
                  type="primary"
                  plain
                  @click="previewFileMixin((jobDataInner || {}).cqPdfId)"
                  style="width: 100%"
                >
                  [{{ (jobDataInner || {}).docNumber }}] CQ File
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item v-if="(jobDataInner || {}).cqPdfId" style="margin-top: 18px">
                <el-button
                  type="primary"
                  plain
                  @click="previewFileMixin((jobDataInner || {}).coqPdfId)"
                  style="width: 100%"
                >
                  [{{ (jobDataInner || {}).docNumber }}] COQ File
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <time-sheet-information v-if="jobDataInner?.timesheet" :jobDataInner ="jobDataInner"/>
        </el-form>
      </base-detail-card>
      <el-form
        ref="schedulForm"
        :model="schedulFormData"
        :disabled="
          !isJobCreatorOrSellerEditView ||
          isCanceled ||
          [3, 4, 5].includes(jobData.status)
        "
        label-position="top"
        :validate-on-rule-change="false"
      >

        <base-detail-card title="Product Information">
          <el-row :gutter="10" v-if="!isEdit">
            <el-col :span="8">
              <el-button
                type="outlined"
                round
                plain
                size="mini"
                @click="dialogTableVisible = true"
                >Select Order</el-button
              >
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="20" :sm="20" :md="8">
              <el-form-item v-if="!isEdit" prop="orderId" label="Linked Order">
                <el-input
                  disabled
                  v-model="schedulFormData.purchaseOrderNumber"
                  style="width: 100%"
                ></el-input>
                <el-dialog title="Link Order" :visible.sync="dialogTableVisible">
                  <el-table :data="orderTableData" ref="multipleTable" height="550px">
                    <el-table-column width="55">
                      <template v-slot="scope">
                        <el-radio v-model="selectedOrder" :label="scope.row">
                          <i></i>
                        </el-radio>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="purchaseOrderNo"
                      label="Order Number"
                      width="170"
                    ></el-table-column>
                    <el-table-column
                      prop="buyerCompanyName"
                      label="Buyer "
                      width="200"
                    ></el-table-column>
                    <el-table-column
                      prop="sellerCompanyName"
                      label="Seller"
                      width="200"
                    ></el-table-column>
                    <el-table-column
                      prop="exWharfLifting.terminal"
                      label="Terminal"
                      width="150"
                    ></el-table-column>
                    <el-table-column
                      prop="products[0].sysProductName"
                      label="Product "
                      width="220"
                    ></el-table-column>
                    <el-table-column
                      prop="products[0].finalQuantity"
                      label="Nomination Qty"
                      width="150"
                    ></el-table-column>
                    <el-table-column
                      prop="remarks"
                      label="Remarks "
                      min-width="260"
                    ></el-table-column>
                  </el-table>
                  <div class="pagination-container">
                    <el-pagination
                      background
                      @size-change="handleOrderPageSizeChange"
                      @current-change="handleOrderPageChange"
                      :page-size.sync="orderPagination.pageSize"
                      :page-sizes="[5, 10, 20, 50, 100]"
                      :current-page="orderPagination.pageNumber"
                      layout="total, sizes, prev, pager, next"
                      :total="orderPagination.total"
                    >
                    </el-pagination>
                  </div>
                  <div slot="footer" class="dialog-footer">
                    <el-button
                      round
                      plain
                      size="small"
                      style="min-width: 100px"
                      @click="dialogTableVisible = false"
                      >Close</el-button
                    >
                    <el-button
                      round
                      type="primary"
                      style="min-width: 100px"
                      size="small"
                      @click="ConfirmDialogTableVisible"
                      >Confirm</el-button
                    >
                  </div>
                </el-dialog>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="8">
              <el-button
                v-if="!isEdit && withOrder"
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="clearOrder(true)"
                style="position: absolute; bottom: 15px"
              ></el-button>
            </el-col>
            <el-col :span="24">
              <p v-if="selectedOrder && selectedOrder.buyerRemark">
                *Remarks: {{ selectedOrder.buyerRemark }}
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="12" :md="8">
              <!-- :rules="!isEdit ? rules.reqRule : null" -->
              <el-form-item
                v-if="
                  schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit
                "
                prop="sellerSysOrganizationId"
                label="Company Name"
              >
                <el-select
                  filterable
                  v-model="schedulFormData.sellerSysOrganizationId"
                  style="width: 100%"
                  placeholder="Select"
                  :clearable="isEdit"
                  :disabled="
                    !isJobCreatorOrSellerEditView || withOrder || (!withOrder && isEdit)
                  "
                >
                  <el-option
                    v-for="item in creditAssociationList"
                    :key="item.id"
                    :label="item.counterpartyCompanyName"
                    :value="item.counterpartySysOrganizationId"
                    :disabled="!isJobCreatorOrSellerEditView || item.status !== 'ACTIVE'"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-else label="Company Name">
                <el-input v-model="schedulFormData.seller" disabled />
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="stemStartDate"
                label="Stem Start Date"
                :rules="rules.reqRule"
              >
                <el-date-picker
                  :disabled="!isJobCreatorOrSellerEditView || withOrder"
                  v-model="schedulFormData.stemStartDate"
                  type="date"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="stemEndDate"
                label="Stem End Date"
                :rules="rules.reqRule"
              >
                <el-date-picker
                  :disabled="!isJobCreatorOrSellerEditView || withOrder"
                  v-model="schedulFormData.stemEndDate"
                  type="date"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductId"
                label="Product Name"
                :rules="rules.reqRule"
              >
                <el-select
                  v-if="withOrder"
                  v-model="schedulFormData.sysProductId"
                  style="width: 100%"
                  placeholder="Select"
                  :disabled="!isJobCreatorOrSellerEditView || (isEdit && withOrder)"
                  @change="changeProduct"

                >
                  <el-option
                    v-for="item in selectedOrder.products"
                    :key="item.sysProductId"
                    :label="item.sysProductName"
                    :value="item.sysProductId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="schedulFormData.sysProductId"
                  style="width: 100%"
                  placeholder="Select"
                  @change="changeProduct"
                   @visible-change="getProductListSortByRecent"
                  :loading="!sysProductOptions.length>0"
                >
                  <el-option
                    v-for="item in sysProductOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item
                prop="sysProductSpecificationId"
                label="Spec"
                :rules="rules.reqRule"
              >
                <el-select
                  v-model="schedulFormData.sysProductSpecificationId"
                  style="width: 100%"
                  placeholder="Select"
                  :disabled="!isJobCreatorOrSellerEditView || withOrder"
                  @change="changeProductSpecification"
                >
                  <el-option
                    v-for="item in sysProductSpecificationOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="loadingQty"
                label="Loading Quantity"
                :rules="rules.loadingQty"
              >
                <el-input
                  v-model="schedulFormData.loadingQty"
                  type="number"
                  class="input-with-select"
                >
                  <el-select
                    v-model="schedulFormData.qtyUnitMetric"
                    slot="append"
                    disabled
                  >
                    <el-option value="MT"></el-option>
                    <el-option value="CBM"></el-option>
                    <el-option value="BARRELS"></el-option>
                    <el-option value="%"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <div v-if="withOrder" class="BalanceDiv">
                <div>
                  <div>Quantity Balance:</div>
                  <div>{{ qtyBalance }}MT</div>
                </div>
                <div>
                  <div>Quantity Assigned:</div>
                  <div>{{ qtyAssigned }}MT</div>
                </div>
                <div>
                  <div>Delivered:</div>
                  <div>{{ balanceData.qtyDelivered }}MT</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="fuelTypeCode"
                label="Fuel Type (For MPA Reporting)"
                :rules="getFuelCodeRules"
              >
                <el-select
                  v-model="schedulFormData.fuelTypeCode"
                  style="width: 100%"
                  placeholder="Select"
                  @visible-change="getMpaFuelTypeListSortByRecent"
                  :loading="!mpaFuelTypeOptions.length>0"
                >
                  <el-option
                    v-for="item in mpaFuelTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item prop="referenceNo" label="Reference No.">
                <el-input v-model="schedulFormData.referenceNo" />
              </el-form-item>
            </el-col>
          </el-row>
          <fuel-type-related-information
            :schedulFormData.sync="schedulFormData"
          />
        </base-detail-card>
        <base-detail-card title="Barge Information">
          <el-row :gutter="10">
            <el-col :sm="12" :md="8">
              <el-form-item
                v-if=" schedulFormData.mitigatingCompanyOrgId === currentCompany.id || !isEdit "
                prop="bargeName" label="Barge Name"
              >
              <barge-or-vessel-selector
                :disabled="!isJobCreatorOrSellerEditView || (jobData.status !== 1 && isEdit)"
                :schedulFormData="schedulFormData"
                :availableBargesLoading="false"
                :availableBargesOptions="currentCompanyShips.filter(item => !((item.imo && item.imo === schedulFormData.vesselImo) ||(item.id === schedulFormData.vesselShipId)))"
                @handleChangeBarge="(val) => changeVesselName(val, true)"
                @visibleChange="()=>{}"
              />
                <span v-if="bargeMpaStatus" style="font-size: 10px"
                  >({{ bargeMpaStatus }})</span
                >
              </el-form-item>
              <el-form-item v-else label="Barge Name">
                <el-input v-model="schedulFormData.bargeName" disabled />
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8">
              <el-form-item label="SB No.">
                <el-input v-model="schedulFormData.bargeLicenseNo" disabled> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </base-detail-card>
        <base-detail-card title="Loading Information">
          <div class="product-container product">
            <div class="single-row-container">
              <div class="label-margin">
                <span class="span-margin"> Location: </span>
                <el-radio-group
                  v-model="schedulFormData.exWharfType"
                  @change="changeExwharfType"
                >
                  <el-radio :label="0">Terminal</el-radio>
                  <el-radio :label="1">Floater</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <el-row :gutter="10">
            <el-col :sm="12" :md="8" :lg="6">
              <el-form-item prop="port" label="Port" :rules="rules.reqRule">
                <el-input v-model="schedulFormData.port"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6">
              <el-form-item prop="loadingTime" label="Date & Time">
                <el-date-picker
                  v-model="schedulFormData.loadingTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  style="width: 100%"
                  placeholder="Select"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6">
              <el-form-item
                v-if="schedulFormData.exWharfType === 1"
                prop="floaterName"
                label="Floater Name"
              >
                <el-input
                  v-model="schedulFormData.floaterName"
                  style="width: 100%"
                  :disabled="prodNameDisabled"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6">
              <el-form-item prop="locationLevel" label="Anchorage/Berth">
                <el-cascader
                  clearable
                  popper-class="el-cascader-long"
                  style="width: 100%"
                  v-model="schedulFormData.locationLevel"
                  :options="locationList"
                  :props="locationOptionProps"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item prop="remark" label="Remarks">
                <el-input type="textarea" :rows="2" v-model="schedulFormData.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </base-detail-card>
        <el-form>
          <base-detail-card
            title="MPA Transaction Information"
            v-if="jobData.status === 4"
            :action="mpaInfoActions"
          >
            <mpa-transaction-information :mpaTransactionData="mpaTransactionData" />
          </base-detail-card>
        </el-form>
      </el-form>
    </div>
  </div>
</template>

<script>
import BaseDetailCard from "@/components/BaseDetailCard";
import { mapState } from "vuex";
import { getTodayISOString, checkIsNotNullOrEmpty } from "@/utils";
import baseMixin from "@/mixins/base-mixin";
import { getJobFiles } from "@/services/modules/ops.js";
import MpaTransactionInformation from "./MpaTransactionInformation.vue";
import JobFiles from "./JobFiles.vue";
import FuelTypeRelatedInformation from "./FuelTypeRelatedInformation.vue"
import TimeSheetInformation from './TimeSheetInformation.vue';
import BargeOrVesselSelector from './BargeOrVesselSelector.vue';
import { sortListByRecent } from "@/utils";
export default {
  props: {
    jobData: {
      type: Object,
      default: () => {},
    },
    mpaTransactionData: Array,
    currentCompanyShips: Array,
    locationOptionProps: Object,
  },
  mixins: [baseMixin],
  components: { BaseDetailCard, MpaTransactionInformation, JobFiles, FuelTypeRelatedInformation,TimeSheetInformation,BargeOrVesselSelector},
  data() {
    return {
      creditAssociationList: null,
      balanceData: "",
      dialogTableVisible: false,
      locationList: this.$store.state.locationList,
      submitLoading: false,
      cancelLoading: false,
      isEdit: false,
      isCanceled: false,
      jobDataInner: null,
      schedulFormData: {
        type: 2, // 1: delivery 2: loading, 3: STS
        orderId: null,
        purchaseOrderNumber: null,
        sysProductId: null,
        sysProductSpecificationId: null,
        productName: null,
        qtyUnitMetric: "MT", //mt/cbm/bbl
        bargeShipId: null,
        bargeName: null,
        bargeLicenseNo: null,
        bargeImo: null,
        bargeInfo: null,
        terminal: null,
        floaterName: null,
        exWharfType: 0, //0: terminal, 1: floater
        estimatedLoadingTime: null,
        loadingTime: null,
        locationLevel1: "", //get these from location api
        locationLevel2: "",
        locationLevel: [],
        remark: "",
        port: "Singapore",
        specs: null,
        loadingQty: null,
        sellerSysOrganizationId: null,
        buyerSysOrganizationId: null,
        stemStartDate: getTodayISOString(),
        stemEndDate: getTodayISOString(),
        tradeRecordId: null,
        tradeRecordOrigin: null,
        hasSurveyor: false,
        mitigatingCompanyOrgId: null,
        bargeOrgId: null, //if specified, use the barge organization’s bdn logo in the report
        minQty: null, //these 3 fields come from order
        maxQty: null,
        finalQty: null,
        Sulphur: "", //0.10% S MAX / 0.50% S MAX / 1.50%S MAX / 3.50% S MAX/ 10PPM / 100PPM / 500PPM
        //below are nomination fields
        operationalRequirement: 1,
        pilotRequired: false,
        tugboatRequired: true,
        numberTugboatRequired: 5,
        nominationEmails: null,
        nominationRemark: null,
        fuelTypeCode: null,
        blendRatio: null,
        biofuelGenCode: null,
        biofuelISCC: null,
        biofuelISCCValue: null,
        biofuelCertScheme: null,
        typeOfFeedstock: null,
      },
      rules: {
        reqRule: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
        ],
        loadingQty: [
          {
            required: true,
            message: "This field is required",
            trigger: ["change", "blur"],
          },
          { validator: this.loadingQuantityValidation, trigger: "change" },
        ],
      },

      // 所有状态为1，2的orderNumber
      searchLoading: false,
      prodNameDisabled: false,
      multipleTable: [],
      orderTableData: [],
      selectedOrderProductSpec: null,
      selectedOrder: null,
      orderSearchKeyword: null,

      sysProductOptions: [],
      sysProductSpecificationOptions: [],
      orderPagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0,
      },
      isPageNumberAndSizeChange: false,
      mpaInfoActions: null,
      jobFiles: {},
      isJobCreatorOrSellerEditView: true,
      mpaFuelTypeOptions:[],
    };
  },
  computed: {
    ...mapState({
      currentCompany: "currentCompany",
      companyList: "companyList",
      shipList: "shipList",
      mpaFuelTypeList: "mpaFuelTypeList",
      productList: "productList",
    }),
    companyOpts() {
      return this.companyList.map((item) => ({
        value: item.id,
        label: item.companyName,
        shipList: "shipList",
      }));
    },
    selectedProduct() {
      if (this.withOrder) {
        return (
          this.selectedOrder.products.find(
            (p) => p.sysProductId === this.schedulFormData.sysProductId
          ) || {}
        );
      } else {
        return (
          this.sysProductOptions.find(
            (p) => p.id === this.schedulFormData.sysProductId
          ) || {}
        );
      }
    },

    withOrder() {
      return this.schedulFormData.orderId !== null && this.schedulFormData.orderId !== "";
    },
    qtyBalance() {
      const finalQty = parseFloat(this.schedulFormData.loadingQty);
      if (!finalQty) {
        return this.balanceData.qtyBalance;
      } else {
        return (this.balanceData.qtyBalance - finalQty).toFixed(5) * 1;
      }
    },
    qtyAssigned() {
      const finalQty = parseFloat(this.schedulFormData.loadingQty);
      if (!finalQty) {
        return this.balanceData.qtyAssigned;
      } else {
        return (this.balanceData.qtyAssigned + finalQty).toFixed(5) * 1;
      }
    },
    bargeMpaStatus() {
      const ship =
        this.shipList?.find(
          (item) =>
            (item.imo && item.imo === this.schedulFormData.bargeImo) ||
            item.id === this.schedulFormData.bargeShipId
        ) ?? null;
      if (!ship) return "Not Found";
      if (ship.lastSyncedMpaBargeFound === null) return "Not Check";
      return ship.lastSyncedMpaBargeFound ? "Exist In MPA" : "Not In MPA";
    },
    getFuelCodeRules() {
      const rules = [
        { required: true, message: this.$t("rejectBeEmpty"), trigger: "change" },
      ];

      return rules;
    },
  },
  methods: {
    sortListByRecent,
    changeLoadingQty(val) {
      this.balanceData.qtyBalance -= val * 1;
      this.balanceData.qtyAssigned += val * 1;
    },
    getOrderById(orderId) {
      return new Promise((resolve, reject) => {
        this.$request
          .get({
            url: `${this.$apis.orders}/${orderId}`,
          })
          .then((res) => {
            if (res.code === 1000) {
              resolve(res.data);
            } else {
              reject("error getting order");
            }
          });
      });
    },

    getProductListSortByRecent (isVisit) {
      if (!this.schedulFormData?.bargeShipId ||this.schedulFormData?.bargeShipId?.trim()=='' || !isVisit) return
      this.$request.get({
        url: `${this.$apis.recentProducts}?shipId=${this.schedulFormData.bargeShipId}`
      }).then(res => {
        if (res?.code === 1000 && res.data?.length>0) {
          const _arr =this.sortListByRecent(this.productList,res.data);
          this.sysProductOptions= _arr
        }
      })
    },
    getMpaFuelTypeListSortByRecent (isVisit) {
      if ( !isVisit ) return
      const productItem =this.schedulFormData?.sysProductId ? this.sysProductOptions.find(product=>product.id == this.schedulFormData?.sysProductId):null
      if (productItem?.oilType.trim()==''|| !productItem?.oilType) {
        this.mpaFuelTypeOptions = [];
      }else{
        this.$store.dispatch("getFilterMpaFuelTypeList",{oilType:productItem.oilType,shipId:this.schedulFormData.bargeShipId}).then((data) => {
          this.mpaFuelTypeOptions = data
        })
      }
    },
    getSysProductSpecifications() {
      this.$request
        .get({
          url: this.$apis.productSpecifications,
        })
        .then((res) => {
          if (res.code === 1000) {
            this.sysProductSpecificationOptions = res.data;
            const defaultSpec = this.sysProductSpecificationOptions.find(
              (spec) => spec.name === "-"
            );
            if (!this.schedulFormData?.sysProductSpecificationId && defaultSpec) {
              this.schedulFormData.sysProductSpecificationId = defaultSpec.id;
            }
          }
        });
    },



    loadingQuantityValidation(rule, value, callback) {
      if (this.withOrder) {
        if (!this.schedulFormData.sysProductId) {
          callback(new Error("Please select a product name first"));
        } else if (!this.balanceData) {
          callback(new Error("Balance Data from the API is invalid"));
        } else if (this.balanceData.qtyBalance === 0) {
          callback(new Error("Quantity Balance is 0"));
        } else if (Number(value) < 1 || Number(value) > this.balanceData.qtyBalance) {
          callback(
            new Error(
              `Loading Quantity must be between 1 and ${this.balanceData.qtyBalance}`
            )
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 请求balance qty
    getBalance() {
      this.$request
        .get({
          url:
            this.$apis.getBalanceNumber +
            this.schedulFormData.orderId +
            "/productName/" +
            this.schedulFormData.sysProductId +
            "/specs/" +
            this.schedulFormData.sysProductSpecificationId +
            "/balanceInfo",
        })
        .then((res) => {
          if (res.code === 1000) {
            this.balanceData = res.data;
          }
        });
    },
    // 确认选择之后关闭模态框
    ConfirmDialogTableVisible(rows) {
      this.dialogTableVisible = false;

      if (this.selectedOrder) {
        this.changeOrder(this.selectedOrder);
      } else {
        this.getProductList();
      }
    },
    //  link order发生变化时触发
    async changeOrder(selectedOrder) {
      this.clearOrder(false);
      if (selectedOrder.purchaseOrderNo) {
        const order = await this.getOrderById(selectedOrder.id);
        this.selectedOrder = order;
        this.schedulFormData.orderId = order.id;
        this.schedulFormData.purchaseOrderNumber = order.purchaseOrderNo;
        this.schedulFormData.port = order.port;
        this.schedulFormData.terminal = order.terminal;
        this.schedulFormData.stemStartDate = order.stemStartDate;
        this.schedulFormData.stemEndDate = order.stemEndDate;
        this.schedulFormData.sellerSysOrganizationId = order.sellerSysOrganizationId;
        this.schedulFormData.buyerSysOrganizationId = order.buyerSysOrganizationId;
        this.selectedOrderProductOptions = order.products;
      }
    },
    changeProduct(sysProductId) {
      if (this.withOrder && sysProductId) {
        this.schedulFormData.sysProductId = this.selectedProduct.sysProductId;
        this.schedulFormData.productName = this.selectedProduct.sysProductName;
        this.schedulFormData.sysProductSpecificationId = this.selectedProduct.sysProductSpecificationId;
        this.schedulFormData.specs = this.selectedProduct.sysProductSpecificationName;
        this.schedulFormData.minQty = this.selectedProduct.minimumQuantity;
        this.schedulFormData.maxQty = this.selectedProduct.maximumQuantity;
        this.schedulFormData.finalQty = this.selectedProduct.finalQuantity;
        this.getBalance();
      } else {
        this.schedulFormData.sysProductId = sysProductId;
        this.schedulFormData.productName = this.sysProductOptions.find(
          (product) => product.id === sysProductId
        )?.name;
        this.schedulFormData.fuelTypeCode = null;
      }
    },
    changeProductSpecification(sysProductSpecificationId) {
      if (this.withOrder) {
        return;
      }

      this.schedulFormData.sysProductSpecificationId = sysProductSpecificationId;
      this.schedulFormData.specs = this.sysProductSpecificationOptions.find(
        (specification) => specification.id === sysProductSpecificationId
      )?.name;
    },
    changeBarge(selectedBargeId) {
      if (!selectedBargeId) return;
      const vessel =
        this.currentCompanyShips?.length > 0 &&
        this.currentCompanyShips.find((v) => v.id == selectedBargeId);
      this.schedulFormData.bargeImo = vessel?.imo;
      this.schedulFormData.bargeShipId = selectedBargeId;
    },
    changeExwharfType(value) {
      this.schedulFormData.floaterName = null;
    },
    clearOrder(clearSelectedOrder) {
      if (clearSelectedOrder) {
        this.selectedOrder = null;
      }

      this.schedulFormData.orderId = null;
      this.schedulFormData.purchaseOrderNumber = null;
      this.schedulFormData.sysProductId = null;
      this.schedulFormData.productName = null;
      this.schedulFormData.sysProductSpecificationId = null;
      this.schedulFormData.specs = null;
      this.schedulFormData.loadingQty = null;
      this.schedulFormData.port = null;
      this.schedulFormData.sellerSysOrganizationId = null;
      this.schedulFormData.minQty = null;
      this.schedulFormData.maxQty = null;
      this.schedulFormData.finalQty = null;
      this.schedulFormData.stemStartDate = null;
      this.schedulFormData.stemEndDate = null;
      this.schedulFormData.terminal = null;
      this.balanceData = "";
    },
    submitForm() {
      this.$refs.schedulForm.validate((valid) => {
        if (valid) {
          this.sendFormData();
        } else {
          this.$message.error("Error. Required fields not filled in.");
          return false;
        }
      });
    },
    async sendFormData() {
      const getRequestData = () => {
        if (!this.withOrder && this.schedulFormData.sellerSysOrganizationId) {
          this.schedulFormData.buyerSysOrganizationId = this.currentCompany.id;
        }
        const requestData = Object.assign({}, this.schedulFormData, {
          loadingTime: this.schedulFormData.loadingTime
            ? new Date(this.schedulFormData.loadingTime).toISOString()
            : null,
          stemStartDate: this.schedulFormData.stemStartDate
            ? new Date(this.schedulFormData.stemStartDate).toISOString()
            : null,
          stemEndDate: this.schedulFormData.stemEndDate
            ? new Date(this.schedulFormData.stemEndDate).toISOString()
            : null,
          locationLevel1:
            this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[0]
              ? this.schedulFormData.locationLevel[0]
              : "",
          locationLevel2:
            this.schedulFormData.locationLevel && this.schedulFormData.locationLevel[1]
              ? this.schedulFormData.locationLevel[1]
              : "",
          loadingQty: Number(this.schedulFormData.loadingQty),
        });

        return requestData;
      };

      if (this.isEdit) {
        // 调用编辑接口
        this.submitLoading = true;
        this.$request
          .put({
            url: this.$apis.editExWharfJob,
            data: getRequestData(),
          })
          .then((data) => {
            if (data?.code === 1000) {
              this.$message.success(data.message);
              this.$emit("close");
            } else {
              this.$message.error(data.message);
            }
          });
      } else {
        // 调用创建接口
        this.submitLoading = true;
        this.$request
          .post({
            url: this.$apis.createNewJob,
            data: getRequestData(),
          })
          .then((data) => {
            if (data?.code === 1000) {
              this.$message.success(data.message);
              this.$emit("close");
            } else {
              this.$message.error(data.message);
            }
          });
      }
      this.$emit("refresh-company-ships");
      this.submitLoading = false;
    },
    cancelJop() {
      this.$confirm("", "Confirm cancel?", {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
        center: true,
        roundButton: true,
      })
        .then(() => {
          this.cancelLoading = true;
          const url = `ebdn/jobs/${this.jobData.id}/cancel`;
          this.$request
            .put({
              url,
            })
            .then((res) => {
              if (res?.code === 1000) {
                this.isCanceled = true;
                this.$message.success("Job cancelled successfully");
                this.$emit("close");
              }
            })
            .finally(() => {
              this.cancelLoading = false;
            });
        })
        .catch(() => {});
    },
    getJobInfoByJobId(jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`;
      this.$request
        .get({
          url,
        })
        .then((res) => {
          if (res?.code === 1000) {
            this.jobDataInner = {
              ...res.data,
            };
          }
        });
    },
    handleOrderPageChange(pageNumber) {
      if (!this.isPageNumberAndSizeChange) {
        this.getOrders(this.orderPagination.pageSize, pageNumber - 1);
      }
    },
    handleOrderPageSizeChange(pageSize) {
      this.isPageNumberAndSizeChange = true;
      this.getOrders(pageSize, 0);
    },
    getOrders(pageSize, pageNumber) {
      const baseSearchCriteria = `buyerSysOrganizationId :'${this.currentCompany.id}' and orderType :'EX_WHARF' and buyerOrderStatus :'CONFIRMED' and exists(products.hasBalance:true and products.status:'PENDING')`;
      const baseSortCriteria = "createdAt desc";
      const queryString = `pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${baseSearchCriteria}&sort=${baseSortCriteria}`;

      this.orderLoading = true;
      this.$request
        .get({
          url: `${this.$apis.orders}?${queryString}`,
        })
        .then((res) => {
          if (res.code === 1000) {
            this.orderTableData = res.data.content;
            this.orderPagination.total = res.data.totalElements;
            this.orderPagination.pageNumber = res.data.pageable.pageNumber + 1;
            this.isPageNumberAndSizeChange = false;
            this.orderLoading = false;
          }
        });
    },
    getProductList() {
      this.$request
        .get({
          url: this.$apis.getProductList,
        })
        .then((res) => {
          if (res.code === 1000) {
            this.productAllOptions = res.data;
          }
        });
    },
    changeVesselName(shipId) {
      const vessel =
        this.currentCompanyShips?.length > 0 &&
        this.currentCompanyShips.find((v) => v.id == shipId);
      this.currentCompanyShips?.length > 0 &&
        this.currentCompanyShips.find((v) => v.id == shipId);
      this.schedulFormData.bargeShipId = shipId;
      this.schedulFormData.bargeName = vessel?.shipName;
      this.schedulFormData.bargeImo = vessel?.imo;
      this.schedulFormData.bargeLicenseNo = vessel?.licenceNo;
    },
  },
  mounted() {
    this.$store.dispatch("getMpaFuelTypeList");
    this.$store.dispatch('getProductList')
    this.getCreditAssociations().then((res) => {
      this.creditAssociationList = res;
    });
    if (this.jobData && Object.keys(this.jobData).length > 0) {
      this.isEdit = true;
      this.isCanceled = this.jobData.status === 5 || this.jobData.status === 4;
      this.schedulFormData = {
        ...this.jobData,
        loadingTime: this.jobData.loadingTime,
        buyerSysOrganizationId: this.currentCompany.id,
        locationLevel: [this.jobData.locationLevel1, this.jobData.locationLevel2],
      };
      this.getJobInfoByJobId(this.jobData.id);

      if ([3, 4].includes(this.jobData.status)) {
        getJobFiles(this.jobData.id).then((res) => {
          this.jobFiles = res.data;
        });
      }

      this.isJobCreatorOrSellerEditView =
        this.currentCompany.id === this.jobData.mitigatingCompanyOrgId ||
        this.currentCompany.id === this.jobData.sellerSysOrganizationId;
      // For compatibility with old data
      if (this.jobData.bargeImo && !this.jobData?.bargeShipId) {
        const shipItem =
          this.shipList?.find((ship) => ship.imo === this.jobData.bargeImo) ?? {};
        this.schedulFormData.bargeShipId = shipItem?.id;
      }
    } else {
      this.schedulFormData.mitigatingCompanyOrgId = this.currentCompany.id;
    }
    this.sysProductOptions = this.productList;
    this.mpaFuelTypeOptions = this.mpaFuelTypeList;
    this.getSysProductSpecifications();
    this.getOrders(this.orderPagination.pageSize, this.orderPagination.pageNumber);

    if (this.withOrder) {
      this.getOrderById(this.schedulFormData.orderId).then((order) => {
        this.selectedOrder = order;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.schedul-detail {
  .fields {
    .classification {
      position: relative;
      overflow: hidden;

      p {
        margin-top: 0;
        font-weight: bold;
        color: #606266;
      }

      .title {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 20px;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: white;
        border-radius: 5px;
        background-color: #0f376a;
      }

      .product-container {
        width: 100%;
        display: flex;

        /deep/ input,
        /deep/ .el-date-editor,
        /deep/ textarea,
        /deep/ .el-input-group__append,
        /deep/ .el-input-group__prepend {
          border: none;
        }

        .delete-btn-block {
          margin-right: 20px;

          > div {
            display: flex;
            align-items: flex-end;
            height: 62px;

            &:first-child {
              height: 84px;
              margin-top: 20px;
            }
          }
        }
      }

      .single-row-container {
        width: calc(100% - 40px);
        box-sizing: border-box;
        overflow-x: auto;
        //margin: 20px;

        > div {
          display: flex;

          .el-form-item:first-child {
            padding-left: 10px;
          }

          .el-form-item:last-child {
            min-width: 200px;
          }

          .delete-btn-block {
            position: absolute;
            right: -30px;
            bottom: 0;
          }
        }
      }

      .delete-btn-item {
        padding: 3px;
      }

      .el-row {
        .cost {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    .product {
      /deep/ input,
      /deep/ .el-date-editor,
      /deep/ textarea,
      /deep/ .el-input-group__append,
      /deep/ .el-input-group__prepend {
        border: none;
      }

      .single-border {
        /deep/ input {
          border-radius: 0;
          border-right: 1px solid #dcdfe680 !important;
        }
      }

      /deep/ .no-border {
        input {
          border: none !important;
        }
      }
    }
  }
  .el-form {
    height: calc(100% - 42px);
    padding-right: 10px;
  }

  .btn-container {
    text-align: right;
    margin-right: 20px;
  }
  .input-with-select {
    /deep/ .el-select .el-input {
      width: 90px;
    }
    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      background-color: #fff;
    }
    .el-select {
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
  }
}
.label-margin {
  margin: 20px 15px 30px 5px;
}
.span-margin {
  margin-right: 15px;
}
.base-detail-card {
  .container {
    height: calc(100% - 42px);

    .el-form {
      padding-right: 10px;
    }

    .contract-number {
      display: flex;
      justify-content: space-between;

      .num-item {
        display: inline-flex;
        flex-direction: column;
        margin: 0 30px 20px 0;

        /deep/ .el-input {
          margin-top: 10px;
          font-size: 18px;

          /deep/ input {
            color: $primary-color;
          }
        }

        .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
/deep/.el-dialog {
  width: 800px;
}
/deep/.el-table .cell {
  white-space: pre-line;
}
.BalanceDiv {
  width: 100%;
  height: 60px;
  line-height: 20px;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/deep/.el-dialog {
  height: 750px;
  width: 1610px;
}
/deep/.el-dialog__body {
  padding: 30px 20px;
  color: #4a4d54;
  font-size: 14px;
  word-break: break-all;
  height: 550px;
}
.el-date-editor {
  width: 100%;
}
</style>
